import { Button } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';
import { TrpcBase } from '../../../utils/trpc';
import { useUserAgent } from '../../../utils/userAgent';
import { dateFormatShow } from '../constants';
import Detail from './detail';
import styles from './print.module.css';
import { printSplitSymbol } from './printSplitSymbol';
import { useCopyFunc } from './useCopyFunc';

export default function ApartmentPrint() {
	const { isMobile, isPC } = useUserAgent();
	const [searchParams, setSearchParams] = useSearchParams();
	const uuid = searchParams.get('uuid');

	if (isNil(uuid)) {
		return <div>id invalid</div>;
	}

	useCopyFunc();

	let uuids: string[] = [];
	if (uuid.indexOf(printSplitSymbol) > 0) {
		uuids = uuid.split(printSplitSymbol);
	} else {
		uuids = [uuid];
	}

	const { data: apartmentDetailStruct, isLoading } =
		TrpcBase.apartment.details.useQuery(
			{
				uuids: uuids,
			},
			{
				enabled: uuids.length > 0,
			},
		);
	const apartmentDetail = apartmentDetailStruct?.detail;

	if (!apartmentDetail) {
		return <div>loading</div>;
	}

	const mData = apartmentDetail;
	const dataList = mData.map((d) => {
		return {
			...d,
			mId: `${dayjs(d.checkInTime).valueOf()}-${dayjs(
				d.checkOutTime,
			).valueOf()}`,
			checkInTime: dayjs(d.checkInTime).format(dateFormatShow),
			checkOutTime: dayjs(d.checkOutTime).format(dateFormatShow),
		};
	});

	if (dataList.length === 1) {
		document.title = `${dataList?.[0]?.roomNumber}房间号`;
	}

	return (
		<div>
			{isPC && (
				<Button
					className={styles.noPrint}
					type="primary"
					onClick={() => {
						window.print();
					}}
				>
					打印
				</Button>
			)}
			{dataList.map((d, i: number) => {
				return (
					<div key={d.uuid}>
						<Detail
							config={apartmentDetailStruct?.feeConfig}
							content={d}
							title={`${d.buildingName}房租单`}
						/>
						{apartmentDetailStruct?.feeConfig?.detailCount !== 1 && (
							<Detail
								config={apartmentDetailStruct?.feeConfig}
								content={d}
								title={`${d.buildingName}房租单`}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
}
